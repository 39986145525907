import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  faHome,
  faArrowLeft,
  faMapMarker,
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
  faCheck,
  faTimes,
  faCoffee,
  faCaretDown,
  faTh,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faHome,
  faArrowLeft,
  faMapMarker,
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
  faCheck,
  faTimes,
  faCoffee,
  faCaretDown,
  faTh,
  faArrowCircleLeft,
  faArrowCircleRight
);

const IconLibrary = {
  ok: "check",
  cancel: "times",
  "sign-in": "sign-in-alt",
  "sign-out": "sign-out-alt",
  "user-circle": "user-circle",
  facebook: ["fab", "facebook-f"],
  twitter: ["fab", "twitter"],
  instagram: ["fab", "instagram"],
  youtube: ["fab", "youtube"],
  linkedin: ["fab", "linkedin"],
  whatsapp: ["fab", "whatsapp"],
  email: ["fab", "email"],
  "circle-left": "arrow-circle-left",
  "circle-right": "arrow-circle-right",
};

const Icon = ({ iconName, ...rest }) => (
  <FontAwesomeIcon
    {...rest}
    icon={iconName in IconLibrary ? IconLibrary[iconName] : iconName}
  />
);

Icon.defaultProps = {
  iconName: "",
};

Icon.propTypes = {
  iconName: PropTypes.string,
};

export default Icon;
