/** @jsx jsx */
import { Box, Image, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";
import donateYatra from "../../static/imgs/Donate_Yatre.png";
import donateFood from "../../static/imgs/Donate_Food.png";
import donateRain from "../../static/imgs/Donate_rain.png";
import deccan from "../../static/imgs/Deccan.png";
import Icon from "../components/Buttons/Icon";
import PressRelease from "../components/PressRelease";
import { pressReleaseContent } from "../../data/donation-packages";

const cardDetails = [
  {
    key: 1,
    image: donateYatra,
    content:
      "A landscaped forest depicting the spiritual chakras and journey of humankind.",
    pgContent: { id: 9, text: "Donate Now" },
  },
  {
    key: 2,
    image: donateFood,
    content:
      "Creating a self-sustaining forest ecosystem of ecologically and economically important food/fruit tree species.",
    pgContent: { id: 9, text: "Donate Now" },
  },
  {
    key: 3,
    image: donateRain,
    content:
      "Curating rare and endangered species for dense mini-forests in the dry region of Telangana.",
    pgContent: { id: 9, text: "Donate Now" },
  },
  {
    key: 4,
    image: deccan,
    content:
      "Selection of hardy drought resistant species of Deccan Peninsula to revive and propagate red-listed and endemic species of great conservation significance.",
    pgContent: { id: 9, text: "Donate Now" },
  },
];

const HeartfulnessGreenFund = () => {
  const press_release_content = pressReleaseContent;

  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness General Fund";
    return `<gatsby_donation amount="0" btntext="${titletext}" donationid=${id} projectid="Heartfulness-green-fund"
         btnbg="#4d8f15" colortext="black" colorprimary="forestgreen" colorsecondary="darkgreen" colorbackground="white"
         colormuted="#f6f6f6" colorhighlight="#efeffe" titletext="${titletext}"/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("heartfulness-green-fund", event),
    []
  );

  return (
    <DonationLayout
      seoData={{
        title: "Heartfulness Green | The Mega Conservation project",
      }}
    >
      <Box>
        <div
          className="banner-gatsby"
          style={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "#fff", fontSize: "55px", marginTop: "10px" }}>
              <span style={{ display: "inline-block" }}>Heartfulness</span>{" "}
              <span
                style={{ color: "hsl(78deg 66% 43%)", display: "inline-block" }}
              >
                Green
              </span>
            </h2>
            <h3 style={{ color: "#fff", fontSize: "28px" }}>
              The Mega Conservation project
            </h3>
            <div>
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(9, "Contribute Now")}
              />
            </div>
          </div>
        </div>
      </Box>
      <Grid style={{ padding: "0px 60px" }}>
        <Box
          sx={{
            my: 5,
            textAlign: "center",
            color: "rgb(24, 168, 107)",
            p: { fontSize: "25px" },
          }}
        >
          <p>
            An environmental initiative committed to nurturing biodiversity,
            indigenous and critically endangered species. An ex-situ
            conservation site – which envisions a space, where humankind and
            nature co-exist in harmony.
          </p>
        </Box>
        <Box>
          <b sx={{ fontSize: "20px" }}>
            Select the projects you would like to support:
          </b>
          <Grid sx={{ textAlign: "center" }} gap={1} columns={[1, 2, 4, null]}>
            {cardDetails &&
              cardDetails.map((item) => (
                <Grid
                  columns={1}
                  sx={{
                    m: 1,
                    minHeight: "500px",
                    px: "12px",
                    backgroundColor: "#fff",
                    backgroundClip: "border-box",
                    border: "1px solid rgba(0,0,0,.125)",
                    borderRadius: ".25rem",
                  }}
                  key={item.key}
                >
                  <Box>
                    <Image src={item.image} alt="card" />
                  </Box>
                  <Box>
                    <p sx={{ my: 2, textAlign: "center" }}>{item.content}</p>
                  </Box>
                  <Box sx={{ alignSelf: "end" }}>
                    <PageContent
                      pageContent={pageContent(
                        item.pgContent.id,
                        item.pgContent.text
                      )}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
          <p sx={{ textAlign: "center", mt: 3, mb: 3 }}>
            {" "}
            <a
              href="https://heartfulness.org/en/green/"
              rel="noreferrer"
              target="_blank"
            >
              Click Here
            </a>{" "}
            to know more about these projects
          </p>
        </Box>
      </Grid>
      <PressRelease htmlsrc={press_release_content} />
      <Box
        sx={{
          background: "linear-gradient(rgb(47, 179, 131), rgb(24, 168, 107))",
          textAlign: "center",
          padding: "40px 0px",
          color: "rgb(255, 255, 255)",
        }}
      >
        <h3>Follow us</h3>
        <p sx={{ textAlign: "center" }}>
          For all queries and interests to contribute / Heartfulness Green
          Initiatives in collaboration with Heartfulness Institute,
          <br />
          Please write to{" "}
          <a sx={{ color: "white" }} href="mailto:green@heartfulness.org">
            green@heartfulness.org
          </a>
        </p>
        <p sx={{ color: "white" }}>
          <a
            href="https://www.facebook.com/kanhashantivanam"
            rel="noreferrer"
            target="_blank"
          >
            <Icon iconName="facebook" />
          </a>{" "}
          <a
            href="https://www.instagram.com/kanhashantivanam/"
            rel="noreferrer"
            target="_blank"
          >
            <Icon iconName="instagram" />
          </a>{" "}
          <a
            href="https://twitter.com/kanhashantivan"
            rel="noreferrer"
            target="_blank"
          >
            <Icon iconName="twitter" />
          </a>{" "}
        </p>
      </Box>
    </DonationLayout>
  );
};

export default HeartfulnessGreenFund;
