/** @jsx jsx */
import { jsx } from "theme-ui";
import Carousel from "react-elastic-carousel";
import HtmlDiv from "sites-common/components/HtmlDiv";
import PropTypes from "prop-types";

const breakPoints = [
  { width: 400, itemsToShow: 1, pagination: false },
  { width: 600, itemsToShow: 2, pagination: false },
  { width: 800, itemsToShow: 3, pagination: false },
];

const PressRelease = (props) => {
  const { htmlsrc } = props;

  return (
    <div>
      <h4 sx={{ fontSize: "1.75rem", textAlign: "center" }}>Press Release</h4>
      <div
        sx={{
          alignCarouselItems: "center",
          justifyContent: "center",
          height: "auto",
        }}
      >
        <Carousel breakPoints={breakPoints}>
          {htmlsrc.map((value) => (
            <div
              key={value.tittle}
              sx={{
                height: "250px",
                color: "#000",
                outline: "none",
                margin: "15px",
                "@media only screen and (max-width: 400px)": {
                  overflow: "scroll",
                },
              }}
            >
              <h6>
                <b>{value.tittle}</b>
              </h6>
              <HtmlDiv htmlString={value.description} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

PressRelease.defaultProps = {
  htmlsrc: [],
};

PressRelease.propTypes = {
  htmlsrc: PropTypes.arrayOf(PropTypes.object),
};

export default PressRelease;
